.errorText {
    color: #cc3300;
}
button[type=a], button[type=button] {
    outline: 0;
}
option {
    outline: 0;
}
.ms-DetailsRow-fields, .ms-List-cell {
    -webkit-user-select: none;
    user-select: none;
}